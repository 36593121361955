import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



function Home_header() {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800); // Check initial window size

    // Dynamically require all images from the /images folder
    const importAll = (r) => {
        let images = {};
        r.keys().forEach((item) => {
            images[item.replace('./', '')] = r(item);
        });
        return images;
    };
    console.log(process.env.REACT_APP_BASE_URL)

    // Load images from directory
    const images = importAll(require.context('../../images', false, /\.(png|jpe?g|svg)$/));

    const toggleMenu = () => {
        if (isMobile) {
            setMenuOpen(!menuOpen);
        }
    };

    // Update the isMobile state on window resize
    const handleResize = () => {
        setIsMobile(window.innerWidth < 800);
        if (window.innerWidth >= 900) {
            setMenuOpen(false); // Close the menu if resizing above 900px
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="header">
            <div className="container">
                <div className="navbar">
                    <div className="logo">
                        <img src={images["bitix_logo.png"]} alt="Bitix Logo" width="90px" />
                    </div>
                    <nav>
                        <ul id="MenuItems" style={{ display: isMobile ? menuOpen ? 'block' : 'none':"" }}>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/products">Products</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/account">Account</Link></li>
                        </ul>
                    </nav>
                   <Link to="/cart"> <i className="bx bx-cart" width="30px" height="30px"></i></Link>
                    <i className="bx bx-menu menu-icon" onClick={toggleMenu}></i>
                </div>
                <div className="row">
                    <div className="col-2">
                        <h1>Evolve with us to the <br /> Digital World!</h1>
                        <p>Take your part in creating the next big thing. <br />Silicon soul Digital heart.</p>
                        <Link to="/products" className="btn">Explore Now &#8594;</Link>
                    </div>
                    <div className="col-2">
                        <img src={images["products.jpg"]} alt="Products" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home_header;
