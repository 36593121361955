import './App.css';
import React, { useState } from 'react';
import ProductList from './pages/products';
import ProjectRoutes from './components/ProjectRoutes/ProjectRoutes';
import header from './components/Header/Header'
import Header from './components/Header/Header';
import { BrowserRouter } from 'react-router-dom';

function App() {

 // Function to dynamically import all images in a directory
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item); // Remove './' to use filename as key
  });
  return images;
};

// Dynamically require all images from the /images folder
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

    // const siteName = "Bitix Technologies";
    const [menuOpen, setMenuOpen] = useState(false);

  // Function to handle menu toggle
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
      



  return (
    

    <>
    <BrowserRouter>
        <ProjectRoutes />
    </BrowserRouter>
        
        
    </>);
}

export default App;
