import React, { useState } from 'react';
import Cookies from "universal-cookie";
import {useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";


const AccountPage = () => {
  const base_url=process.env.REACT_APP_BASE_URL
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true); // Manage form state for login/register

  // States for registration form inputs
  const [registrationData, setRegistrationData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });

  // States for login form inputs
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  // Function to handle form switching
  const handleLogin = () => {
    setIsLogin(true);
  };

  const handleRegister = () => {
    setIsLogin(false);
  };

  // Function to handle registration form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle login form input changes
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission for registration
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(base_url+'/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });

      if (response.ok) {
        const data = await response.json();
        alert('Registration successful!');
        console.log('Registered user:', data);
      } else {
        const errorData = await response.json();
        alert(`Registration failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during registration.');
    }
  };

  // Function to handle form submission for login
 

const handleLoginSubmit = async (e) => {
  e.preventDefault();

  const { email, password } = loginData;

  try {
    const response = await fetch(base_url+'/login', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`${email}:${password}`),  // Set Basic Auth header
      },
    });

    if (response.ok) {
      const data = await response.json();
      alert('Login successful!');


      // Save tokens in cookies
      cookies.set('access_token', data.access_token, { path: '/', maxAge: data.expires });
      cookies.set('refresh_token', data.refresh_token, { path: '/' });

      console.log('Tokens saved in cookies:', {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      }
    );
    const decodedToken = jwtDecode(data.access_token);
     console.log('Decoded Token:', decodedToken);
    navigate('/');

    } else {
      const errorData = await response.json();
      alert(`Login failed: ${errorData.message}`);
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred during login.');
  }
};

  // Function to dynamically load images
  const importAll = (r) => {
    let images = {};
    r.keys().map((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };

  const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

  return (
    <div>
      {/* Account Page */}
      <div className="account-page">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                  alignContent: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                What is my Purpose?
              </h2>
              <img src={images['purpose_robot.png']} alt="Purpose Robot" width="100%" />
              <marquee behavior="typing" direction="down" scrollamount="1">
                <h2
                  style={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  Remake the World with us !!
                </h2>
              </marquee>
            </div>
            <div className="col-2">
              <div className="form-container">
                <div className="form-btn">
                  <span onClick={handleLogin}>Login</span>
                  <span onClick={handleRegister}>Register</span>
                  <hr
                    id="Indicator"
                    style={{
                      transform: isLogin ? 'translateX(0)' : 'translateX(100px)',
                      transition: 'transform 0.5s ease',
                    }}
                  />
                </div>

                {/* Conditionally render the forms with sliding effect */}
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <form
                    id="LoginForm"
                    onSubmit={handleLoginSubmit} // Submit login form
                    style={{
                      position: 'absolute',
                      left: isLogin ? '0' : '-300px',
                      transition: 'left 0.5s ease',
                    }}
                  >
                    <input
                      type="email"
                      name="email"
                      value={loginData.email}
                      onChange={handleLoginChange}
                      placeholder="Email"
                      required
                    />
                    <input
                      type="password"
                      name="password"
                      value={loginData.password}
                      onChange={handleLoginChange}
                      placeholder="Password"
                      required
                    />
                    <button type="submit" className="btn">Login</button>
                    <a href="/">Forgot Password</a>
                  </form>

                  <form
                    id="RegForm"
                    onSubmit={handleRegisterSubmit} // Submit registration form
                    style={{
                      position: 'absolute',
                      left: isLogin ? '300px' : '0',
                      transition: 'left 0.5s ease',
                    }}
                  >
                    <input
                      type="text"
                      name="name"
                      value={registrationData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      value={registrationData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      required
                    />
                    <input
                      type="password"
                      name="password"
                      value={registrationData.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                      required
                    />
                    <input
                      type="text"
                      name="phone"
                      value={registrationData.phone}
                      onChange={handleInputChange}
                      placeholder="Phone (254712345678)"
                      required
                    />
                    <button type="submit" className="btn">Register</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
