import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';

const ProductsPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartId, setCartId] = useState('');
  const navigate = useNavigate();

  const cookies = new Cookies();

  useEffect(() => {
    fetchCartItems(); // Load cart items when the component mounts
  }, []);

  const fetchCartItems = async () => {
    const token = cookies.get('access_token');
    try {
      const response = await fetch('http://127.0.0.1:5000/api/cart/token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.ok) {
        const data = await response.json();
        setCartId(data.public_id);
        setCartItems(data.cart_item);
        setTotalPrice(data.total_price);
      } else {
        console.error('Failed to fetch cart items');
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle updating quantity
  const handleQuantityChange = async (cartId, item_id, newQuantity) => {
    const token = cookies.get('access_token');
    const requestBody = {
      product_id: String(item_id),
      quantity: newQuantity
    };

    try {
      const response = await fetch(`http://127.0.0.1:5000/api/cart/${cartId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        await fetchCartItems(); // Refresh cart items and total price after updating quantity
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  // Function to handle incrementing quantity
  const increaseQuantity = (cartId, item) => {
    handleQuantityChange(cartId, item.cartItems.public_id, item.quantity + 1);
  };

  // Function to handle decrementing quantity
  const decreaseQuantity = (cartId, item) => {
    if (item.quantity > 1) {
      handleQuantityChange(cartId, item.cartItems.public_id, item.quantity - 1);
    }
  };

  // Function to handle removing an item
  const handleRemoveItem = async (cartItemId) => {
    const token = cookies.get('access_token');
    try {
      const response = await fetch(`http://127.0.0.1:5000/api/cart/${cartId}/${cartItemId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.ok) {
        setCartItems((prevItems) => prevItems.filter((item) => item.cartItems.public_id !== cartItemId));
        await fetchCartItems(); // Refresh the cart items after removing an item
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
      navigate('/cart');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="small-container cart-page">
        <div className="checkout-div">
          <div className="cart-items">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Subtotal</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="cart-info">
                          <img src={item.cartItems.product_images[0]?.image || 'no_image.png'} alt={item.cartItems.name} />
                          <div>
                            <p>{item.cartItems.name}</p>
                            <small>Price: Ksh {item.price.toFixed(2)}</small>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="quantity-container">
                          <button onClick={() => decreaseQuantity(cartId, item)}>-</button>
                          <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            style={{ width: "50px" }}
                            onChange={(e) => handleQuantityChange(cartId, item.cartItems.public_id, parseInt(e.target.value))}
                          />
                          <button onClick={() => increaseQuantity(cartId, item)}>+</button>
                        </div>
                      </td>
                      <td>Ksh {item.total_price.toFixed(2)}</td>
                      <td>
                        <button onClick={() => handleRemoveItem(item.cartItems.public_id)}>Remove</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">Your cart is empty.</td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3"><strong>Total</strong></td>
                  <td><strong>Ksh {totalPrice.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Link to="/checkout" className="btn">
                      Checkout
                    </Link>
                  </td>
                  <td>
                    <Link to="/clear-cart" className="btn">
                      Clear cart
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
