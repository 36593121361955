import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "../../pages/products.jsx";
import ProductDetail from "../../pages/productDetail.jsx";
import Header from "../Header/Header.jsx"; // Import your header component
import Home_header from "../Home_Header/Home_header.jsx";
import Footer from "../Footer/footer.jsx";
import Homepage from "../../pages/homepage.jsx";
import AddProductForm from "../../pages/add_product.jsx";
import Cart from "../../pages/cart.jsx";
import AcccountPage from "../../pages/register.jsx";
import Categories from "../../pages/categories.jsx";
import SubCategories from "../../pages/sub-categories.jsx";

const ProjectRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home_header />

              <Homepage />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <Header />
              <Product />
            </>
          }
        />
        <Route
          path="/products/:productId"
          element={
            <>
              <Header />
              <ProductDetail />
            </>
          }
        />

        <Route
          path="/add_product"
          element={
            <>
              <Header />

              <AddProductForm />
            </>
          }
        />

        <Route
          path="/cart"
          element={
            <>
              <Header />
              <Cart />
            </>
          }
        />
        <Route
          path="/signin"
          element={
            <>
              <Header />
              <AcccountPage />
            </>
          }
        />

        <Route
          path="/categories"
          element={
            <>
              <Header />
              <Categories />
            </>
          }
        />

        <Route
          path="/sub-categories"
          element={
            <>
              <Header />
              <SubCategories />
            </>
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

export default ProjectRoutes;
