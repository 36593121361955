import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies(); // Create a Cookies instance

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [mainImage, setMainImage] = useState('');
  const [clickedImage, setClickedImage] = useState('');
  const [productid, setProductid] = useState('');
  const [quantity, setQuantity] = useState(1);

  const navigate = useNavigate();
  const [token, setToken] = useState(cookies.get('access_token')); // Use the Cookies instance

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`http://127.0.0.1:5000/api/products/${productId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch product');
        }
        const data = await response.json();
        setProduct(data);
        if (data.product_images && data.product_images.length > 0) {
          setMainImage(data.product_images[0].image);
          setClickedImage(data.product_images[0].image);
        }
        setLoading(false);
        setProductid(data.public_id);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  const handleImageClick = (imageUrl) => {
    setMainImage(imageUrl);
    setClickedImage(imageUrl);
  };

  const handleImageHover = (imageUrl) => {
    setMainImage(imageUrl);
  };

  const handleImageOut = () => {
    setMainImage(clickedImage);
  };
    const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Function to handle quantity decrease
  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
  };

  // Function to handle quantity input change
  const handleQuantityChange = (event) => {
    const value = Math.max(1, parseInt(event.target.value, 10) || 1); // Ensure the value is at least 1
    setQuantity(isNaN(value) ? 0 : value);
  };

  const createCart = async (cartData) => {
  const cartEndpoint = 'http://127.0.0.1:5000/api/cart';
  const token = cookies.get('access_token');

  try {
    const response = await fetch(cartEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({cart_item:[cartData]}),
    });

    const responseData = await response.json();
    if (response.ok) {
      alert('Cart created or updated successfully.');
      navigate('/cart');
      console.log(responseData);
    } else if (response.status === 400) {
      alert(`Validation error: ${responseData.message}`);
    } else if (response.status === 404) {
      alert(`Error: ${responseData.message}`);
    } else {
      alert(`Unexpected error: ${responseData.message}`);
    }
  } catch (error) {
    console.error('Error creating cart:', error);
    alert('Failed to create or update the cart.');
  }
};

const addCartItem = async (cartId, productData) => {
  const cartItemEndpoint = `http://127.0.0.1:5000/api/cart/${cartId}`;
  const token = cookies.get('access_token');

  try {
    const response = await fetch(cartItemEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    const responseData = await response.json();
    if (response.ok) {
      alert('Product added to cart successfully.');
      console.log(responseData);
      navigate('/cart');
    } else if (response.status === 400) {
      alert(`Validation error: ${responseData.message}`);
    } else if (response.status === 404) {
      alert(`Error: ${responseData.message}`);
    } else if (response.status === 409) {
      alert('Product already in cart.');
    } else {
      alert(`Unexpected error: ${responseData.message}`);
    }
  } catch (error) {
    console.error('Error adding item to cart:', error);
    alert('Failed to add product to cart.');
  }
};

  const editCartItem = async (cartId, productData) => {
  const editCartEndpoint = `http://127.0.0.1:5000/api/cart/${cartId}`;
  const token = cookies.get('access_token');

  try {
    const response = await fetch(editCartEndpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    const responseData = await response.json();
    if (response.ok) {
      alert('Cart item quantity updated successfully.');
      console.log(responseData);
      navigate('/cart');
    } else if (response.status === 400) {
      alert(`Validation error: ${responseData.message}`);
    } else if (response.status === 404) {
      alert(`Error: ${responseData.message}`);
    } else {
      alert(`Unexpected error: ${responseData.message}`);
    }
  } catch (error) {
    console.error('Error editing cart item:', error);
    alert('Failed to update cart item.');
  }
};

  const ToCart = async (productData) => {
  const cartEndpoint = 'http://127.0.0.1:5000/api/cartrequest';
  const token = cookies.get('access_token'); // Get the token from the Cookies instance

  try {
    const response = await fetch(cartEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        product_id: productid,
        quantity: 1 // Adjust quantity as needed
      })
    });

    const responseData = await response.json();

    switch (response.status) {
      case 200:
       
        alert('Product exists in cart pending update.');
        editCartItem(responseData.cartid, productData);

        break;
      case 201:
        console.log(responseData);
        alert('Product added to cart.');
        addCartItem(responseData.cartid, productData);
        break;
      case 203:

        alert('Cart not found. Creating a new cart.');
        createCart(productData);
        break;
      case 400:
        alert(`Validation error: ${responseData.message}`);
        break;
      case 404:
        alert(`Product does not exist: ${responseData.message}`);
        break;
      default:
        alert(`Unexpected error: ${responseData.message}`);
    }
  } catch (error) {
    console.error('Error adding to cart:', error);
    alert('Failed to add product to cart.');
  }
};

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="small-container single-product">
      
      <div className="row">
        {product && Object.keys(product).length > 0 && (
          <>
            <div className="col-2">
              <img
                src={mainImage || 'http://127.0.0.1:5000/static/images/no_image.png'}
                alt={product.name}
                width="100%"
                id="ProductImg"
              />
              <div className="small-img-row">
                {product.product_images &&
                  product.product_images.map((image, index) => (
                    <div className="small-img-col" key={index}>
                      <img
                        src={image.image}
                        alt=""
                        width="100%"
                        className="small-img"
                        onClick={() => handleImageClick(image.image)}
                        onMouseOver={() => handleImageHover(image.image)}
                        onMouseOut={handleImageOut}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-2">
              <p>{product.product_category ? product.product_category.name : 'Category'}</p>
              <h1>{product.name}</h1>
              <h4>Ksh {product.price}</h4>
              <select>
                <option value="">Select Model</option>
                <option value="model1">Model 1</option>
                <option value="model2">Model 2</option>
                <option value="model3">Model 3</option>
                <option value="model4">Model 4</option>
              </select>
                <div className="quantity-container">
                <button onClick={decreaseQuantity}>-</button>
                <input
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange} // Handle manual input change
                  min="1"
                />
                <button onClick={increaseQuantity}>+</button>
              </div>
             <button className="btn" onClick={() => ToCart({ product_id: product.public_id, quantity: quantity })}>
                Add To Cart
              </button>

              <h3>Product Details <i className="bx bx-right-indent"></i></h3>
              <p>{product.summary_description}</p>
              <div className="product-details">
                {product.details && product.details.length > 0 ? (
                  product.details.map((detail, index) => (
                    <div key={index} className="detail-item">
                      <strong>{detail.title}:</strong> {detail.description}
                    </div>
                  ))
                ) : (
                  <p>No details available for this product.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
