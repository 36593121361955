// ProductFetcher.js
import React, { useState, useEffect } from 'react';
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const ProductFetcher = ({ setProducts, setLoading }) => {
  const base_url=process.env.REACT_APP_BASE_URL
  const cookies = new Cookies();
  const navigate = useNavigate();

  // Store token and its expiration time
  const [token, setToken] = useState(cookies.get('access_token'));
  const [refreshtoken, setRefreshToken] = useState(cookies.get('refresh_token'));
  const [expiresAt, setExpiresAt] = useState(cookies.get('expires_at'));

  const fetchProducts = async () => {
    console.log("baseurl ",base_url)

    try {
      const response = await fetch(`${base_url}/products/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setRefreshToken(cookies.get('refresh_token'));
        // console.log('refreshtoken: ', refreshtoken, 'token: ', token);
        if (refreshtoken) {
          console.log( "trying to get new token: ", 'refreshtoken: ', refreshtoken, 'token: ', token);
          const newToken = await refreshToken();
          if (newToken) {
            console.log("we got a new token: ", newToken);
            setToken(newToken);
          } else {
            console.error('Token refresh failed. Please log in again.');
            navigate('/signin');
            return;
          }
        }
        navigate('/signin');
        throw new Error('Failed to fetch products');
        
      }

      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    const token = "Token Expired";

    const requestBody = {
      access_token: token,
      refresh_token: refreshtoken,
    };

    const response = await fetch(`${base_url}/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    });

    if (response.ok) {
      const data = await response.json();
      setToken(data.access_token);
      setExpiresAt(data.expires_at);
      cookies.set('access_token', data.access_token, { path: '/' });
      cookies.set('expires_at', data.expires_at, { path: '/' });
      return data.access_token;
    }
    return null;
  };

  useEffect(() => {
    const now = new Date().getTime();
    const expiryTime = new Date(expiresAt).getTime();

    const fetchData = async () => {
      if (now >= expiryTime) {
        const newToken = await refreshToken();
        if (newToken) {
          await fetchProducts();
        } else {
          console.error('Token refresh failed. Please log in again.');
        }
      } else {
        fetchProducts();
      }
    };

    fetchData();
  }, [token, expiresAt]);

  return null; // This component doesn't render anything
};

export default ProductFetcher;
