// Homepage.js
import React, { useState } from 'react';
import ProductFetcher from '../components/product-fetcher/product_fetcher';
import ProductDisplay from './home_page';

const Homepage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <ProductFetcher setProducts={setProducts} setLoading={setLoading} />

      {loading ? (
        <div>Loading...</div>
      ) : (
        <ProductDisplay products={products} />
      )}
    </>
  );
};

export default Homepage;
