import React, { useEffect, useState } from "react";
import axios from "axios";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editData, setEditData] = useState({ name: "", description: "" });
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", description: "" });
  const base_url = process.env.REACT_APP_BASE_URL;

  // Fetch categories from the API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(base_url + "/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle input change for edit fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  // Add category
  const addCategory = async () => {
    try {
      await axios.post(base_url + "/categories", newCategory);
      setShowModal(false); // Close the modal
      setNewCategory({ name: "", description: "" }); // Reset fields
      fetchCategories(); // Refresh categories after adding
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  // Handle updating an existing category
  const updateCategory = async () => {
    try {
      await axios.put(base_url + `/categories/${editCategoryId}`, editData);
      setEditCategoryId(null); // Clear edit ID
      setEditData({ name: "", description: "" }); // Reset fields
      fetchCategories(); // Refresh categories after updating
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  // Handle deleting a category
  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(base_url + `/categories/${categoryId}`);
      fetchCategories(); // Refresh categories after deletion
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  // Open the edit form for a specific category
  const openEditForm = (category) => {
    setEditCategoryId(category.public_id);
    setEditData({ name: category.name, description: category.description });
  };

  return (
    <div className="container-fluid position-relative d-flex p-0 categories-container">
      <div className="sidebar pe-4 pb-3">{/* Sidebar Navigation */}</div>

      <div className="content">
        <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
          <a href="#" className="navbar-brand">
            Categories
          </a>
          <div className="ml-auto categories-ribbon">
            <button
              className="btn btn-primary mx-2"
              onClick={() => setShowModal(true)}
            >
              Add Category
            </button>
            <button className="btn btn-secondary" onClick={fetchCategories}>
              Refresh
            </button>
          </div>
        </nav>
        {/* Add Category Modal */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Add New Category</h3>
              <input
                type="text"
                name="name"
                value={newCategory.name}
                placeholder="Category Name"
                onChange={(e) =>
                  setNewCategory({ ...newCategory, name: e.target.value })
                }
                className="form-control mb-2"
              />
              <input
                type="text"
                name="description"
                value={newCategory.description}
                placeholder="Category Description"
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    description: e.target.value,
                  })
                }
                className="form-control mb-2"
              />
              <button className="btn btn-success" onClick={addCategory}>
                Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        <table className="table mt-4">
          <thead>
            <tr>
              <th>Category ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <React.Fragment key={category.public_id}>
                <tr>
                  <td>{category.public_id}</td>
                  <td>{category.name}</td>
                  <td>{category.description}</td>
                  <td>
                    <button
                      className="btn btn-edit"
                      onClick={() => openEditForm(category)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-delete"
                      onClick={() => deleteCategory(category.public_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>

                {/* Inline edit form below the row */}
                {editCategoryId === category.public_id && (
                  <tr>
                    <td colSpan="4">
                      <div className="p-3 edit-form">
                        <input
                          type="text"
                          name="name"
                          value={editData.name}
                          placeholder="Category Name"
                          onChange={handleInputChange}
                          className="form-control mb-2"
                        />
                        <input
                          type="text"
                          name="description"
                          value={editData.description}
                          placeholder="Category Description"
                          onChange={handleInputChange}
                          className="form-control mb-2"
                        />
                        <div className="save-cancel-btn">
                          <button
                            className="btn btn-success mr-2"
                            onClick={updateCategory}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setEditCategoryId(null)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Categories;
