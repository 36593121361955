import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Make sure Header is simple and only uses Link after it's inside BrowserRouter
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Dynamically require all images from the /images folder
  const importAll = (r) => {
    let images = {};
    r.keys().map((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };

  // Load images from directory
  const images = importAll(require.context('../../images', false, /\.(png|jpe?g|svg)$/));

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header">
      <div className="container">
        <div className="navbar">
          <div className="logo">
            <img src={images["bitix_logo.png"]} alt="Bitix Logo" width="90px" />
          </div>
          <nav>
            <ul id="MenuItems">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/products">Products</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/account">Account</Link></li>
            </ul>
          </nav>
          <Link to="/cart" className='bx bx-cart'></Link>
          <i className="bx bx-menu menu-icon" onClick={toggleMenu}></i>
        </div>
      </div>
    </div>
  );
};

export default Header;
