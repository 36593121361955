import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './products.css';

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState('default'); // State to track the selected sort criteria

  useEffect(() => {
    // Function to fetch the products
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/products/');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data); // Assuming the API returns an array of products
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value); // Update the sort criteria when user selects an option
  };

  const sortProducts = (products, criteria) => {
    switch (criteria) {
      case 'price':
        return [...products].sort((a, b) => a.price - b.price); // Sort by price (ascending)
      case 'popularity':
        return [...products].sort((a, b) => b.sales - a.sales); // Sort by popularity (assuming sales data available)
      case 'rating':
        return [...products].sort((a, b) => b.rating - a.rating); // Sort by rating (descending)
      case 'newest':
        return [...products].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by newest (date)
      default:
        return products; // Default sorting
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Sort the products based on the selected criteria
  const sortedProducts = sortProducts(products, sortCriteria);

  return (
    <>
      <div className='small-container'>
        <div className="row row-2">
          <h1>All Products</h1>
          <select value={sortCriteria} onChange={handleSortChange}>
            <option value="default">Default Sorting</option>
            <option value="price">Sort by price</option>
            <option value="popularity">Sort by popularity</option>
            <option value="rating">Sort by rating</option>
            <option value="newest">Sort by newest</option>
          </select>
        </div>

        {sortedProducts.length > 0 ? (
          <div className="product-grid">
            {/* Loop through all sorted products and render each */}
            {sortedProducts.map((product, index) => (
              <div className="col-4" key={index}>
                <img
                  style={{ width: '200px', height: '200px' }}
                  src={product.product_images && product.product_images.length > 0 
                    ? product.product_images[0].image 
                    : 'http://127.0.0.1:5000/static/images/no_image.png'} // Default image
                  alt={product.name}
                />

                <h4>{product.name}</h4>
                <p>{product.product_category.name}</p>
                <div className="rating">
                  {[...Array(product.rating)].map((_, i) => (
                    <i key={i} className="bx bxs-star"></i>
                  ))}
                </div>
                <p>Ksh {product.price}</p>
                <Link to={`/products/${product.public_id}`}>View Details</Link>
              </div>
            ))}
          </div>
        ) : (
          <p>No products available.</p>
        )}

        <div className="page-btn">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>&#8594;</span>
        </div>
      </div>
    </>
  );
}

export default ProductsList;
