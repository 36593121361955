// ProductDisplay.js
import React from 'react';
import { Link } from 'react-router-dom';

const ProductDisplay = ({ products }) => {
  // Filter products added within the last 5 days
  const getLatestProducts = (products) => {
    const today = new Date();
    const fiveDaysAgo = new Date(today);
    fiveDaysAgo.setDate(today.getDate() - 5);

    return products.filter(product => {
      const productDate = new Date(product.created_at);
      return productDate >= fiveDaysAgo;
    });
  };

  // Get the latest products
  const latestProducts = getLatestProducts(products);

  return (
    <>
      {/* ######### featured categories ######## */}
      <div className="categories">
        <div className="small-container">
          <div className="row">
            <div className="col-3">
              <img src="../static/images/product1.png" alt="" />
            </div>
            <div className="col-3">
              <img src="../static/images/product2.png" alt="" />
            </div>
            <div className="col-3">
              <img src="../static/images/product1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* ######### featured products ######## */}
      <div className="small-container">
        <h2 className="title">Featured Products</h2>
        {products.length > 0 ? (
          <div className="row">
            {products.map((product, index) => (
              <div className="col-4" key={index}>
                <img
                  src={product.product_images && product.product_images.length > 0
                    ? product.product_images[0].image
                    : 'http://127.0.0.1:5000/static/images/no_image.png'}
                  alt={product.name}
                />
                <h4>{product.name}</h4>
                <div className="rating">
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bx-star"></i>
                </div>
                <p>Ksh {product.price}</p>
                <Link to={`/products/${product.public_id}`}>View Details</Link>
              </div>
            ))}
          </div>
        ) : (
          <p>No products available.</p>
        )}
      </div>

      {/* ######### Latest products ######## */}
      <div className="small-container">
        <h2 className="title">Latest Products</h2>
        {latestProducts.length > 0 ? (
          <div className="row">
            {latestProducts.map((product, index) => (
              <div className="col-4" key={index}>
                <img
                  src={product.product_images && product.product_images.length > 0
                    ? product.product_images[0].image
                    : 'http://127.0.0.1:5000/static/images/no_image.png'}
                  alt={product.name}
                />
                <h4>{product.name}</h4>
                <div className="rating">
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bx-star"></i>
                </div>
                <p>Ksh {product.price}</p>
                <Link to={`/products/${product.public_id}`}>View Details</Link>
              </div>
            ))}
          </div>
        ) : (
          <p>No latest products available.</p>
        )}
      </div>

      {/* More sections here (offer, testimonials, brands) */}
    </>
  );
};

export default ProductDisplay;
